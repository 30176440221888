import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { Notifications } from 'shared/lib/generated/notifications/Api'
import { downloadFileWithPath } from 'shared/lib/utils'

interface IUseNotificationsStore {
  webSocket: any,
  setWebSocket: (newWebSocket: any) => void
  messages: Notifications[]
  setMessage: (messages: Notifications[]) => void
  sendMessage: (message: any) => void
  showMessage: boolean
  setShowMessage: (show: boolean) => void
  switchShowMessage: () => void
}

const triggerNotification = () => {
  const bellElement = document.getElementById('notification-bell-icon')
  if (bellElement) {
    bellElement.classList.add('shaking')
    setTimeout(() => {
      bellElement.classList.remove('shaking')
    }, 500)
  }
}

const autoDownload = (message: Notifications) => {
  if (message.data.media?.download) {
    const url = new URL(message.data.media?.url)
    downloadFileWithPath(url.pathname, message.data.media!.title || '')
  }
}

export const useNotificationsStore = create<IUseNotificationsStore>()(devtools((set, get) => {
  const setMessage = (messages: Notifications[]) => set((state) => {
    if (get().webSocket?.readyState === WebSocket.OPEN) {
      const isNewMessage = messages.length === 1 && messages?.[0].status === 'created'
      if (isNewMessage) {
        triggerNotification()
        autoDownload(messages[0])
      }

      return isNewMessage ? { messages: messages.concat(state.messages) } : { messages }
    }
    return { messages: state.messages }
  })

  const sendMessage = (message: any) => {
    const ws = get().webSocket
    if (ws && ws?.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify(message))
    }
  }

  return {
    messages: [],
    setWebSocket: (ws) => set(() => ({ webSocket: ws })),
    setMessage,
    sendMessage,
    webSocket: null,
    showMessage: false,
    setShowMessage: (show) => set({ showMessage: show }),
    switchShowMessage: () => set(({ showMessage }) => ({ showMessage: !showMessage })),
  }
}))