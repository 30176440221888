import React from 'react'
import { pimApi } from 'shared/api'
import { Image } from 'shared/ui/components'
import { Link } from 'react-router-dom'
import { generatePath } from 'react-router'
import { PRODUCT_EDIT } from 'shared/config'

import { InfoWrapper } from './styled'

const fileThumbnailSize = { maxWidth: 70, maxHeight: 70 }

export const ProductInfoCell = ({ row }) => {
  const apiUrl = pimApi.products.getFileThumbnailApiPath(
    row.productId,
    fileThumbnailSize
  )
  
  return (
    <InfoWrapper>
      <Image src={apiUrl} />

      <div className="product-info">
        <Link to={ generatePath(PRODUCT_EDIT, { id: row.productId }) }>{ row.name }</Link>
        <span>{ row.vendorCode }</span>
        <a target="_blank" rel="noreferrer" href={row.marketplaceUrl}>{ row.sku }</a>
      </div>
    </InfoWrapper>
  )
}