import { gql } from '@apollo/client'
import { IAvailableFilters } from 'pages/product/productList/model/types'

export const getProductsList = (filtersConfig?: IAvailableFilters) => gql(`
  query MyQuery(
    $abcClass: [ABCClass!] = []
    $autoOrdering: Boolean = null
    $cardStatusId: [Int!] = []
    $category: [String!] = []
    $companyId: [Int!] = []
    $companymarketplaceId: [Int!] = []
    $contragentId: [Int!] = []
    $orderStatus: OrderStatus = null
    $orderStatusInterval: Int = 30
    $excludeInArchive: Boolean = false
    $goodsBrandId: [Int!] = []
    $goodsType: [GoodsType!] = null
    $marketplaceId: [Int!] = []
    $productId: Int = null
    $searchString: String = null
    $stocksStatus: StocksStatus = null
    $syncStatusId: [Int!] = []
    $xyzClass: [XYZClass!] = []
    $limit: Int,
    $page: Int! = 0,
    $order: SortOrder = null,
    $sort: String = null) {
    products(
      searchString: $searchString,
      excludeInArchive: $excludeInArchive,
      abcClass: $abcClass,
      autoOrdering: $autoOrdering,
      category: $category,
      cardStatusId: $cardStatusId,
      companyId: $companyId,
      companymarketplaceId: $companymarketplaceId,
      contragentId: $contragentId,
      goodsBrandId: $goodsBrandId,
      goodsType: $goodsType,
      marketplaceId: $marketplaceId,
      productId: $productId,
      stocksStatus: $stocksStatus,
      orderStatus: $orderStatus,
      orderStatusInterval: $orderStatusInterval,
      syncStatusId: $syncStatusId,
      xyzClass: $xyzClass,
      ) {
      total
      result(
        limit: $limit,
        page: $page,
        order: $order,
        sort: $sort
        ) {
        abcClass
        autoOrdering
        barcodes
        brand
        cabinetName
        cardStatus {
          description
          name
          status
        }
        companyName
        days30Orders
        days30OrdersItems
        days30Sales
        days30SalesItems
        orderGrowthFactor
        goodsType
        hasPhoto
        inArchive
        inSupply
        marketplaceCategory
        marketplaceCode
        marketplaceName
        marketplaceId
        name
        marketplaceUrl
        newProduct
        price
        productId
        stocks
        stocksDate
        supplierCode
        suppliers
        syncStatus {
          description
          name
          status
        }
        xyzClass
      }
      filters {
      ${filtersConfig ? Object.keys(filtersConfig).map(el => {
    if (filtersConfig[el] === true) {
      return `${el} { value label count }`
    }
    return ''
  }) : `
        abcClass {
          label
          value
          count
        }
        xyzClass {
          label
          value
          count
        }
        marketplaceId {
          label
          value
          count
        }
        autoOrdering {
          label
          value
          count
        }
        category {
          label
          value
          count
        }
        goodsBrandId {
          label
          value
          count
        }
        companyId {
          label
          value
          count
        }
        contragentId {
          label
          value
          count
        }
        syncStatusId {
          label
          value
          count
        }
      ` 
}
      }
    }
  }
  `
)
