import { memo, PropsWithChildren, useCallback, useMemo } from 'react'
import { Paper } from '@mui/material'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { user } from 'shared/api'
import { isNotNil } from 'shared/lib/checkers'
import { Table } from 'shared/ui/components/Table'
import { SearchObj } from 'shared/ui/components/Table/model/types'
import { checkValue } from 'shared/lib/checkers/checkValue'
import { useQuery } from '@apollo/client'
import { Query as ProductType } from 'shared/lib/generated/products/Api'
import { stringToBoolean } from 'shared/lib/utils'
import { Plugin, Template, TemplatePlaceholder } from '@devexpress/dx-react-core'
import { toSnake } from 'shared/lib/transform/toSnakeCase'
import { ProductPayload } from 'shared/api/pim/excel/products'
import { getProductsList } from 'shared/api/products/getProductsList'
import { arrOfNum, arrOfString } from 'shared/lib/transform'
import { ProductsOrderStatus } from 'shared/config'

import { tableColumnsConfig } from './model'
import { Header } from './ui/Header/Header'
import { StyledTableBox, StyledLoader } from './ui/styled'
import { pagingPanelLocale } from './lib/localization'
import { TableFilter } from './ui/Filter/TableFilter'
import { FiltersType, IAvailableFilters } from './model/types'

const ROW_HEIGHT = 49
const TABLE_HEIGHT = 690
const MAX_PAGE_SIZE = 50
const MIN_ROWS_COUNT = -4
const DEDAULT_ROWS_COUNT = 5

const getRowId = (row) => row.productId

const Products = ({
  children,
  columnsConfig = tableColumnsConfig,
  columnsSettingsId,
  availableFilters,
  showOrderStatus
}: PropsWithChildren<{
  columnsConfig?: any,
  availableFilters?: IAvailableFilters,
  columnsSettingsId: string,
  showOrderStatus?: boolean
}>) => {
  const { search } = useLocation()
  const searchObj: SearchObj = queryString.parse(search, { arrayFormat: 'bracket', parseBooleans: true })

  const bonusCols = Math.floor((window.innerHeight - TABLE_HEIGHT) / ROW_HEIGHT)
  const calculatedRowsPerPage = bonusCols > MIN_ROWS_COUNT ? DEDAULT_ROWS_COUNT + bonusCols : DEDAULT_ROWS_COUNT

  const {
    searchString, companyId, marketplaceId, syncStatusId, goodsBrandId,
    sort, order, limit, page, inArchive, contragentId,
    autoOrdering, abcClass, stocksStatus, companymarketplaceId, xyzClass, category,
    orderStatus, orderStatusInterval
  } = searchObj

  const currentPage = parseInt(page || '0', 10)
  const currentRowsPerPage = isNotNil(limit) ? parseInt(limit, 10) : calculatedRowsPerPage
  const pageSize = Math.min(MAX_PAGE_SIZE, currentRowsPerPage)

  const filtersListParams: FiltersType = {
    marketplaceId: arrOfNum(marketplaceId) || [],
    syncStatusId: arrOfNum(syncStatusId) || [],
    contragentId: arrOfNum(contragentId) || [],
    autoOrdering: autoOrdering?.[0],
    orderStatus: checkValue(orderStatus?.[0]),
    orderStatusInterval: orderStatusInterval?.[0] ? Number(orderStatusInterval?.[0]) : undefined,
    companymarketplaceId: arrOfNum(companymarketplaceId) || [],
    stocksStatus: checkValue(stocksStatus?.[0]),
    category: arrOfString(category) || [],
    abcClass: arrOfString(abcClass) || [],
    xyzClass: arrOfString(xyzClass) || [],
    companyId: arrOfNum(companyId) || [],
    goodsBrandId: arrOfNum(goodsBrandId) || []
  }

  const goodsListParams = {
    searchString: checkValue(searchString),
    excludeInArchive: checkValue(inArchive),
    productId: checkValue(searchObj.productId),
    sort: checkValue(sort),
    order: checkValue(order),
    limit: pageSize,
    page: currentPage,
  }

  const downloadProductsFileParams = {
    ...goodsListParams,
    ...filtersListParams,
    excludeInArchive: !stringToBoolean(goodsListParams.excludeInArchive) }

  const { data, loading, previousData } = useQuery<ProductType, ProductPayload>(getProductsList(availableFilters), {
    variables: {
      ...filtersListParams,
      sort: goodsListParams.sort ? toSnake(goodsListParams.sort) : null,
      // @ts-ignore
      order: goodsListParams?.order?.toUpperCase(),
      page: goodsListParams.page,
      productId: Number(goodsListParams.productId),
      limit: goodsListParams.limit,
      searchString: goodsListParams.searchString,
      excludeInArchive: !goodsListParams.excludeInArchive
    }, context: { apiName: 'products' } })

  const sortTableParams = searchObj.sort && searchObj.order
    ? [{ columnName: searchObj.sort, direction: searchObj.order }]
    : []

  const memoTableParams = useMemo(
    () => ({ sort: sortTableParams, limit: pageSize, page: currentPage, searchString, columnsSettingsId: columnsSettingsId || 'products' }),
    [data?.products.result])

  const { data: userSettingsQuery, isLoading: settingsLoading } = user.settings.useFetchUserSettingsQuery()
  const { mutate: updateUserSettings } = user.settings.useUpdateUserSettingsMutation()

  const tableHiddenColumns = userSettingsQuery?.data?.productTableHiddenColumns

  const pluginDependencies = [
    { name: 'Toolbar' }
  ]

  const handleTableSettingsChange = useCallback((hiddenColumns) => {
    updateUserSettings({ settings: { ...userSettingsQuery?.data, productTableHiddenColumns: hiddenColumns } })
  }, [userSettingsQuery?.data])

  const tableData = useMemo(() => data || previousData, [data, previousData])
  
  const tableFilters = {
    ...tableData?.products?.filters,
    ...(showOrderStatus && {
      orderStatus: tableData?.products?.filters.orderStatus?.map(el => ({
        ...el,
        label: ProductsOrderStatus[el.value]
      })) || []
    })
  }
  return (
    <>
      <Header downloadParams={downloadProductsFileParams}/>
      <StyledTableBox component={Paper}>
        {(loading || settingsLoading) && (<StyledLoader size={60} />)}
        {(tableData && userSettingsQuery?.data) && (
          <Table
            tableList={tableData.products.result}
            totalCount={tableData.products.total}
            getRowId={getRowId}
            handleTableSettingsChange={handleTableSettingsChange}
            tableHiddenColumns={availableFilters ? undefined : tableHiddenColumns}
            tableParams={memoTableParams}
            calculatedRowsPerPage={calculatedRowsPerPage}
            columnsConfig={columnsConfig}
            showInArchiveControl={true}
            showColumnsVisibility={true}
            searching="external"
            pagination="external"
            sorting="external"
            filtering="internal"
            paginationLocale={pagingPanelLocale}
          >
            <Plugin dependencies={pluginDependencies}>
              <Template name="toolbarContent">
                <TemplatePlaceholder/>
                <TableFilter
                  showOrderStatus={showOrderStatus}
                  filters={tableFilters}
                  filtersListParams={filtersListParams}
                />
              </Template>
              { children }
            </Plugin>
          </Table>
        )}
      </StyledTableBox>
    </>
  )
}
export const MemoProducts = memo(Products)
