import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { routesList } from 'shared/ui/components/Sidebar/routes'

interface ISidebarStore {
  sidebarOpen: boolean
  setSidebarOpen: (e: boolean) => void
  expand: Array<{ index: number, open: boolean }>,
  setExpand: (e) => void
  forcedSidebarState: boolean
  setForcedSidebarState: (e: boolean) => void
}

export const WEB_LOW_WIDTH = 1448

export const useSidebarStore = create<ISidebarStore>()(persist(devtools((set) => {
  const routes = routesList()
  const setSidebarOpen = (open: boolean) => set(() => ({ sidebarOpen: open }))
  
  const expand = routes.map((_, index) => ({
    index,
    open: false
  }))

  const setForcedSidebarState = (open: boolean) => set(() => ({ forcedSidebarState: open }))

  const setExpand = (e) => set(() => ({ expand: e }))
  return {
    sidebarOpen: true,
    setSidebarOpen,
    forcedSidebarState: false,
    setForcedSidebarState,
    expand,
    setExpand
  }
}), {
  name: 'sidebar',
  version: 1,
  partialize: (state) => ({ expand: state.expand, sidebarOpen: state.sidebarOpen })
}))