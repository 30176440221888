/* eslint-disable max-len */
import React from 'react'
import { pimApi } from 'shared/api'
import { DefaultModal } from 'shared/ui/components/ModalComponents'
import { dialogModel } from 'shared/ui/components/dialog'
import { snackActions } from 'shared/lib/react/snackbar'
import { ArchiveModal } from 'features/product/WB/ArchiveModal'
import { ProductCopyModal } from 'features/product/WB/ProductCopyModal/ProductCopyModal'
import { ProductChangeGoodsModal } from 'features/product/ProductChangeGoodsModal'
import { SettingsMenuItem } from 'pages/product/edit/main/model/types'
import { useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { useCommonProductContext } from 'entities/pim/product/model/common/useCommonProductContext'
import { useWBProductContext } from 'entities/pim/product/model/wb/useWBProductContext'
import { PRODUCT_EDIT, PRODUCT_LIST } from 'shared/config'
import { isNil } from 'shared/lib/checkers'
import { generatePath } from 'react-router'
import { isEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'

export const ProductSettingsHandlers = () => {
  const queryClient = useQueryClient()
  const { id } = useParams<{ id: string }>()
  const productId = parseInt(id, 10)
  const history = useHistory()
  const { commonProductQuery } = useCommonProductContext()
  const { wbProductQuery } = useWBProductContext()
  const { mutate: unarchiveProductMutation } = pimApi.products.common.useUnarchiveProductMutation()
  const { mutate: deleteProductMutation } = pimApi.products.wb.useDeleteProductMutation()
  const { mutate: setProductAsNewCardMutation } = pimApi.products.wb.useSetProductAsNewCardMutation()
  const { mutate: operationWithBasket } = pimApi.products.wb.useOperationsWithProductsBasket()

  const nomenclaturesQuantity = wbProductQuery?.data?.otherNomenclatures?.length

  const variations = wbProductQuery?.data?.variations
  const variationSelected = variations?.find((variation) => variation?.productId === productId)

  const isArchived = variationSelected ? variationSelected?.inArchive : wbProductQuery?.data?.inArchive

  const productInBasket = wbProductQuery?.data?.syncStatus.description === 'Карточка в корзине'

  const archiveOperations = () => {
    if (isArchived && commonProductQuery.data) {
      dialogModel.openDialog(
        {
          component: ({ close, accept }) =>
            <DefaultModal
              close={close}
              accept={accept}
              variant="contained"
              acceptBtnColor="primary"
              title="Вернуть продукт из архива?"
            />,
          onAccept: () => {
            unarchiveProductMutation({
              productId: commonProductQuery.data?.productId,
              deArchivationInfo: {
                productId: commonProductQuery.data?.productId,
                versionNo: commonProductQuery.data?.versionNo,
              }
            }, {
              onSuccess: ({ data }) => {
                queryClient.setQueryData(pimApi.products.common.getFetchCommonProductQueryKey(productId), data)
                snackActions.info('Продукт успешно возвращен из архива!')
                wbProductQuery.refetch().then()
              }
            })
          }
        }
      )
    } else {
      dialogModel.openDialog(
        {
          component: ({ close, accept }) =>
            commonProductQuery.data && <ArchiveModal close={close} accept={accept} product={commonProductQuery.data}/>,
          onAccept: () => {},
        }
      )
    }
  }
  const deleteProduct = () => {
    dialogModel.openDialog(
      {
        component: ({ close, accept }) =>
          <DefaultModal
            close={close}
            accept={accept}
            acceptBtnText="Удалить"
            title={wbProductQuery?.data?.nmid ?
              `Удалить номенклатуру ${wbProductQuery?.data?.nmid}?` : 'Действительно удалить?'}
          />,
        onAccept: () => {
          deleteProductMutation(
            {
              productId,
              id: wbProductQuery?.data?.id,
              versionNo: wbProductQuery?.data?.versionNo,
            },
            {
              onSuccess: () => {
                if (isEmptyArray(wbProductQuery?.data?.otherNomenclatures)) {
                  history.push(PRODUCT_LIST)
                } else {
                  history.push(generatePath(PRODUCT_EDIT, { id: wbProductQuery?.data?.otherNomenclatures?.[0].productId! }))
                }
                snackActions.info('Номенклатура успешно удалена!')
              },
            }
          )
        }
      }
    )
  }

  const copyProduct = () => {
    dialogModel.openDialog(
      {
        component: ({ close }) =>
          <ProductCopyModal wbProductQuery={wbProductQuery.data} commonProductData={commonProductQuery!.data!} close={close}/>
      }
    )
  }

  const setProductAsNewCard = () => {
    dialogModel.openDialog(
      {
        component: ({ close, accept }) =>
          <DefaultModal
            close={close}
            accept={accept}
            title={`Вы уверены, что хотите создать отдельную карточку для номенклатуры ${wbProductQuery?.data?.nmid || wbProductQuery?.data?.vendorCode || wbProductQuery?.data?.name}?`}
          />,
        onAccept: () => {
          setProductAsNewCardMutation(
            {
              productId
            },
            {
              onSuccess: () => {
                snackActions.info('Сохранено!')
                wbProductQuery.refetch().then()
              },
            }
          )
        },
      }
    )
  }

  const basketOperations = () => {
    dialogModel.openDialog(
      {
        component: ({ close, accept }) =>
          <DefaultModal
            close={ close }
            accept={ accept }
            variant="contained"
            acceptBtnColor="primary"
            title={productInBasket ? 'Вернуть из корзины?' : 'Перенести в корзину?'}
          />,
        onAccept: () => {
          operationWithBasket({ productId, method: productInBasket ? 'delete_from_basket' : 'add_to_basket' }, {
            onSuccess: () => {
              snackActions.info(productInBasket ? 'Продукт возвращен из корзины' : 'Продукт перенесен в корзину' )
            }
          })
        }
      }
    )
  }

  const transferToAnotherProduct = () => {
    dialogModel.openDialog(
      {
        component: ({ close, accept }) => <ProductChangeGoodsModal close={close} accept={accept} productId={id}/>,
        onAccept: () => {},
      }
    )
  }

  const settingsOptions: Array<SettingsMenuItem> = [
    {
      label: 'Сделать отдельной карточкой',
      disabled: isNil(nomenclaturesQuantity) || nomenclaturesQuantity===0 || isArchived,
      handler: setProductAsNewCard
    },
    {
      label: 'Создать копию',
      handler: copyProduct
    },
    {
      label: 'Перенести на другой товар',
      disabled: isArchived,
      handler: transferToAnotherProduct
    },
    {
      label: productInBasket ? 'Вернуть из корзины' : 'Перенести в корзину',
      disabled: isNil(wbProductQuery?.data?.nmid) || isArchived || nomenclaturesQuantity === 0,
      handler: basketOperations
    },
    {
      label: isArchived ? 'Вернуть из архива': 'Перенести в архив',
      divider: true,
      handler: archiveOperations
    },
    {
      label: wbProductQuery?.data?.nmid ? 'Удалить' : 'Удалить черновик',
      type: 'error',
      handler: deleteProduct
    },
  ]

  return { settingsOptions }
}
