import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'
import { SearchResultType } from 'shared/lib/generated/products/Api'


type ColumnName =
  | keyof Omit<
  SearchResultType,
  | 'versionNo'
  | 'barcodes'
  | 'marketplaceUrl'
  | 'stocksDate'
  | 'suppliers'
  | 'brand'
  | 'cabinetName'
  | 'companyName'
  |'cardStatus'
  | 'syncStatus'
  | 'inArchive'
  | 'goodsType'
  | 'newProduct'
>


type ColumnNames = Record<ColumnName, ColumnName>

export const columns: ColumnNames = {
  name: 'name',
  goodsId: 'goodsId',
  orderGrowthFactor: 'orderGrowthFactor',
  abcClass: 'abcClass',
  xyzClass: 'xyzClass',
  hasPhoto: 'hasPhoto',
  marketplaceName: 'marketplaceName',
  marketplaceCategory: 'marketplaceCategory',
  marketplaceCode: 'marketplaceCode',
  marketplaceId: 'marketplaceId',
  stocks: 'stocks',
  price: 'price',
  inSupply: 'inSupply',
  supplierCode: 'supplierCode',
  days30Sales: 'days30Sales',
  days30SalesItems: 'days30SalesItems',
  days30Orders: 'days30Orders',
  days30OrdersItems: 'days30OrdersItems',
  autoOrdering: 'autoOrdering',
  productId: 'productId'
}

interface ColumnsConfigProps {
  path?: string
  config: Array<ColumnConfig<ColumnName>>
  isEditable?: boolean
  onCommitChanges?: () => void
}

export const columnsConfig: ColumnsConfigProps = {
  config: [
    {
      name: columns.abcClass,
      columnName: columns.abcClass,
      title: 'ABC',
      width: 90,
      sortingEnabled: true,
      cellType: CellType.ProductAbcClass
    },
    {
      name: columns.xyzClass,
      columnName: columns.xyzClass,
      title: 'XYZ',
      width: 89,
      sortingEnabled: true,
      align: 'center',
      cellType: CellType.ProductXyzClass,
    },
    {
      name: columns.productId,
      columnName: columns.productId,
      title: 'Фото',
      width: 70,
      sortingEnabled: false,
      cellType: CellType.Photo,
    },
    {
      name: columns.name,
      columnName: columns.name,
      title: 'Наименование',
      width: 'auto',
      sortingEnabled: false,
      cellType: CellType.NameLink
    },
    {
      name: columns.autoOrdering,
      columnName: columns.autoOrdering,
      title: 'Автозаказ',
      width: 120,
      sortingEnabled: true,
      cellType: CellType.AutoOrdering
    },
    {
      name: columns.marketplaceName,
      columnName: columns.marketplaceName,
      title: 'Маркетплейс',
      width: 140,
      sortingEnabled: false,
    },
    {
      name: columns.marketplaceCategory,
      columnName: columns.marketplaceCategory,
      title: 'Категория',
      width: 150,
      sortingEnabled: false,
    },
    {
      name: columns.marketplaceCode,
      columnName: columns.marketplaceCode,
      title: 'Номенклатура маркетплейса',
      width: 150,
      wordWrapEnabled: true,
      sortingEnabled: true,
      cellType: CellType.MarketplaceCode,
    },
    {
      name: columns.stocks,
      columnName: columns.stocks,
      title: 'Остатки',
      width: 108,
      sortingEnabled: true,
      cellType: CellType.ChartsSale
    },
    {
      name: columns.inSupply,
      columnName: columns.inSupply,
      title: 'В поставке',
      width: 130,
      sortingEnabled: true,
      cellType: CellType.ChartsSale
    },
    {
      name: columns.price,
      columnName: columns.price,
      title: 'Цена.р.',
      width: 108,
      sortingEnabled: true,
      cellType: CellType.ChartsSale
    },
    {
      name: columns.days30Sales,
      columnName: columns.days30Sales,
      title: 'Сумма продаж за 30 дней, р.',
      width: 160,
      wordWrapEnabled: true,
      sortingEnabled: true,
      cellType: CellType.ChartsSale
    },
    {
      name: columns.days30SalesItems,
      columnName: columns.days30SalesItems,
      title: 'Кол-во продаж за 30 дней',
      width: 160,
      wordWrapEnabled: true,
      sortingEnabled: true,
      cellType: CellType.ChartsSale
    },
    {
      name: columns.orderGrowthFactor,
      columnName: columns.orderGrowthFactor,
      title: 'Коэффициент роста',
      width: 160,
      wordWrapEnabled: true,
      sortingEnabled: true,
      cellType: CellType.MathRoundCell
    }
  ],
}

