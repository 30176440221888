import { Table } from '@devexpress/dx-react-grid-material-ui'
import { ChangeSet, Column } from '@devexpress/dx-react-grid'
import { customerApi, pimApi } from 'shared/api'
import { isNil, isNotNil } from 'shared/lib/checkers'
import {
  getAdminEditCompanyPath,
  getAdminEditSupplierPath,
  getAdminEditUserPath,
  getGoodsEditPath,
  getOrderEditPath,
  getProductEditPath
} from 'shared/config'
import { isEmptyArray } from 'shared/lib/checkers/isNotEmptyArray'
import { BarcodesTooltip } from 'entities/pim/barcodes'
import { DateFormats } from 'shared/config/dateFormats'
import { UserState } from 'features/admin/User/EditUser/ui/UserState'
import React from 'react'

import { DeleteIcon, EditInModalIcon, OpenModalIcon, StyledTableCell } from './styled'

import { CellType } from '../../model/types'
import {
  ActionPriceCell,
  ActivityProductLink, AttributeTypeCell,
  AutoOrderCell, BooleanCell,
  ChartUrlCell,
  CompaniesStateCell,
  CompanyPhotoCell,
  CopackingCostCell,
  DateAndUserNameCell,
  DateCell,
  DaysWithStocksCell,
  DefaultCell,
  DeltaIconCell,
  DocumentWithLinkCell,
  EditField,
  EditQuantity,
  EditTemplateCell,
  GoodsTypeCell,
  HistoryStatus,
  IncomingPriceCell,
  IsEmptyNull,
  LinkNameBlankCell,
  LinkNameCell,
  MarketplaceLinkCell,
  MarketplaceWithIcon, MathRoundCell,
  NameValueArraySumCell,
  NewPriceCell,
  NomenclatureCell,
  OldPriceCell,
  OrderDeltaItems,
  OrderLinkCell,
  OrderStatusCell,
  PackagingRequirement,
  PercentContainer,
  PercentsDiffCell,
  PhotoCell,
  PositionsBarcode,
  PositionsDiff,
  PositionsProductLink,
  PositionsVendorCode,
  PriceCell,
  PricesCell,
  PrivilegeEditCell,
  PrivilegeRemoveCell,
  PrivilegesNameCell,
  PrivilegeUsersQuantityCell,
  ProductAbcClass,
  ProductNameCell,
  ProductXyzClass,
  Profitability,
  ProfitabilitySum,
  QuantityInSupply,
  RatingPercent,
  RepriceAutoChangeCell,
  RepriceCommentsCell,
  RepriceCurrentPrice,
  RepriceFinalPriceCell,
  RepriceIncomingPriceCell,
  RepriceMinPriceCell,
  RepriceNameCell,
  RepriceNewPriceCell,
  RepriceStocksCell,
  RoundedNumber, SettingsMenuCell,
  StatusCell,
  SumCell,
  UserDateEditCell,
  UserDateInCell,
  UserDateOutCell,
  UserPhotoCell,
  WordWrapCell
} from '../TableCells'
import { Image } from '../../../Image'
import { MarketplaceWithNm } from '../TableCells/MarketplaceWithNm'
import { CopackingOrderLink } from '../TableCells/CopackingOrderLink'
import { RelativeDateCell } from '../TableCells/RelativeDateCell'
import { PositionsEditQuantity } from '../TableCells/PositionsEditQuantity'
import { IMenuItem } from '../../../SettingsMenu'
import { RowSelectCell } from '../TableCells/RowSelectCell'

const fileThumbnailSize = { maxWidth: 70, maxHeight: 70 }

function getApiUrl(row) {
  let apiUrl

  if (isNil(row.hasPhoto)) {
    apiUrl = pimApi.products.getFileThumbnailApiPath(
      row.productId,
      fileThumbnailSize
    )
  }
  if (row.hasPhoto) {
    if (row.productId) {
      apiUrl = pimApi.products.getFileThumbnailApiPath(
        row.productId,
        fileThumbnailSize
      )
    } else {
      apiUrl = pimApi.goods.getGoodsThumbnailApiPath(row.id, fileThumbnailSize)
    }
  }

  return apiUrl
}

export function getPhotoUrl(id) {
  return customerApi.admin.getUsersPhoto(id)
}


const getCell = (
  row: any,
  cellValue: string,
  cellColumnName: string,
  cellTypeValue?: CellType,
  cellValuePath?: string,
  allRows?: boolean,
  onRowDelete?: (id: UniqueId) => void,
  onModalOpen?: (id: UniqueId) => void,
  onCellChange?: (changes: ChangeSet) => void,
  rowSelection?: {
    selectedCondition: (e) => boolean
    onRowSelect: (row) => void
  },
  dataFormat?: DateFormats,
  isLoading?: boolean,
  isShowGreen?: boolean,
  isSingleString?: boolean,
  isTableEditable?: boolean,
  editingEnabled?: boolean, 
  settingsMenuOptions?: (e) => Array<IMenuItem>
) => {

  const isArchived = row.inArchive

  switch (cellTypeValue) {
    case CellType.GoodsType:
      return <GoodsTypeCell goodsType={row.goodsType} />

    case CellType.Photo:
      return <PhotoCell apiUrl={getApiUrl(row)} isArchived={isArchived} />

    case CellType.NameLink: {
      const path = row.productId
        ? getProductEditPath(row.productId)
        : getGoodsEditPath(row.goodsId || row.id)
      return (
        <LinkNameCell
          name={
            row.name || row.productName || row.goodsName || 'название продукта'
          }
          path={path}
          isArchived={isArchived}
          isSingleString={isSingleString}
        />
      )
    }

    case CellType.ActivityProductLink: {
      const path = row.productId ? getProductEditPath(row.productId) : null
      return (
        <ActivityProductLink path={path} name={row.productName}/>
      )
    }

    case CellType.AutoOrdering: {
      return (
        <AutoOrderCell value={cellValue}/>
      )
    }

    case CellType.NameLinkBlank: {
      const path = row.productId
        ? getProductEditPath(row.productId)
        : getGoodsEditPath(row.goodsId || row.id)
      return (
        <LinkNameBlankCell
          name={
            row.name || row.productName || row.goodsName || 'название продукта'
          }
          path={path}
          isArchived={isArchived}
          isSingleString={isSingleString}
        />
      )
    }

    case CellType.ChartUrl: {
      return (
        <ChartUrlCell row={row}/>
      )
    }

    case CellType.DocumentWithLink: {
      return (
        <DocumentWithLinkCell
          fileName={row.fileName}
          fileUrl={row.fileUrl}
        />
      )
    }

    case CellType.OrderLink: {
      const path = getOrderEditPath(row.id)
      return <OrderLinkCell name={row.orderNumber} path={path} />
    }

    case CellType.SupplierLink: {
      const path = getAdminEditSupplierPath(row.id)
      const name = row.contragent
        ? row.contragent
        : row.shortName
      return <OrderLinkCell name={name} path={path} isArchived={isArchived}/>
    }

    case CellType.ActionPriceCell: {
      return <ActionPriceCell row={row}/>
    }
    
    case CellType.UserLink: {
      const path = getAdminEditUserPath(row.id)
      const { name } = row
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: row,
            alignItems: 'center',
            gap: '10px' }}>
          <div>
            <UserPhotoCell
              apiUrl={getPhotoUrl(row.id)}
              name={row.name}/>
          </div>
          <OrderLinkCell name={name} path={path} isArchived={isArchived}/>
        </div>
      )
    }

    case CellType.CompaniesLink: {
      const path = getAdminEditCompanyPath(row.id)
      const { name } = row
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: row,
            alignItems: 'center',
            gap: '32px' }}>
          <div>
            <CompanyPhotoCell row={row}/>
          </div>
          <OrderLinkCell fontSize={16} name={name} path={path} isArchived={isArchived}/>
        </div>
      )
    }
    
    case CellType.CompaniesState: {
      return (
        <CompaniesStateCell value={cellValue}/>
      )
    }

    case CellType.RepriceNameLink: {
      const path = getProductEditPath(row.productId)
      return (
        <RepriceNameCell row={row} path={path}/>
      )
    }

    case CellType.RepricePhoto: {
      const { photo } = row
      return (
        <div style={{ width: 50, height: 50 }}>
          <Image style={{ objectFit: 'contain', borderRadius: '4px' }} width="50" height="50" src={photo} alt="qwe"/>
        </div>
      )
    }

    case CellType.RepriceStocks: {
      const { stocks, perDay } = row
      return ( <RepriceStocksCell stocks={stocks} perDay={perDay}/> )
    }

    case CellType.RepriceCurrentPrice: {
      return (
        <RepriceCurrentPrice row={row}/>
      )
    }

    case CellType.RepriceNewPrice: {
      return (
        <RepriceNewPriceCell row={row}/>
      )
    }

    case CellType.RepriceMinPrice: {
      return (
        <RepriceMinPriceCell row={row}/>
      )
    }

    case CellType.RepriceIncomingPrice: {
      return (
        <RepriceIncomingPriceCell row={row}/>
      )
    }

    case CellType.RepriceFinalPrice: {
      return (
        <RepriceFinalPriceCell row={row}/>
      )
    }
    
    case CellType.PriceHistoryDate: {
      return (
        <DateAndUserNameCell row={row}/>
      )
    }

    case CellType.RepriceComments: {
      const { comments } = row
      return (
        <RepriceCommentsCell comments={comments}/>
      )
    }

    case CellType.PriceHistoryNomenclature: {
      return (
        <NomenclatureCell row={row}/>
      )
    }

    case CellType.PriceHistoryName: {
      return (
        <ProductNameCell row={row}/>
      )
    }

    case CellType.PriceHistoryNewPrice: {
      return (
        <NewPriceCell row={row}/>
      )
    }
    case CellType.PriceHistoryCopackingCost: {
      return (
        <CopackingCostCell row={row}/>
      )
    }

    case CellType.PriceHistoryOldPrice: {
      return (
        <OldPriceCell row={row}/>
      )
    }

    case CellType.PriceHistoryIncomingPrice: {
      return (
        <IncomingPriceCell row={row}/>
      )
    }

    case CellType.PriceHistoryDeltaIcon: {
      return (
        <DeltaIconCell row={row}/>
      )
    }

    case CellType.RepriceAutoChange: {
      return (
        <RepriceAutoChangeCell row={row}/>
      )
    }

    case CellType.ChartsSale: {
      return (
        <span>{cellValue?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</span>
      )
    }
    
    case CellType.MathRoundCell: {
      return (
        <MathRoundCell cellValue={cellValue}/>
      )
    }

    case CellType.PercentContainer: {
      return (<PercentContainer row={row}/>)
    }
    
    case CellType.CoPackingOrderLink: {
      return <CopackingOrderLink row={row}/>
    }

    case CellType.RelativeDateCell: {
      return <RelativeDateCell stringDate={row?.created}/>
    }

    case CellType.RatingPercent: {
      return <RatingPercent name={row.rating} />
    }

    case CellType.MarketplaceNameLink: {
      const path = getProductEditPath(row.productId)
      return <LinkNameCell name={row.marketplaceName} path={path} />
    }

    case CellType.Barcodes: {
      const isEmptyBarcodes = isNil(row.barcodes) || isEmptyArray(row.barcodes)

      if (isEmptyBarcodes) {
        return undefined
      }
      return (
        <BarcodesTooltip
          barcodes={row.barcodes as Array<string>}
          disabled={isArchived}
        />
      )
    }

    case CellType.MarketplaceCode: {
      if (isNil(row.marketplaceCode)) {
        return undefined
      }
      if (isNotNil(row.marketplaceUrl)) {
        return (
          <MarketplaceLinkCell
            url={row.marketplaceUrl}
            title={row.marketplaceCode}
            isArchived={isArchived}
          />
        )
      }

      return <span title={row.marketplaceCode}>{row.marketplaceCode}</span>
    }

    case CellType.MarketplaceLink: {
      if (isNotNil(row.marketplaceUrl)) {
        return (
          <MarketplaceLinkCell
            url={row.marketplaceUrl}
            title={row.sku}
            isArchived={isArchived}
          />
        )
      }
      return <span title={row.marketplaceCode}>{row.marketplaceCode}</span>
    }
    
    case CellType.MarketplaceWithNm: {
      return <MarketplaceWithNm row={row}/>
    }

    case CellType.ProfitabilitySum: {
      return <ProfitabilitySum cellValue={cellValue}/>
    }
    
    case CellType.Profitability: {
      return <Profitability cellValue={cellValue}/>
    }

    case CellType.MarketplaceWithIcon: {
      if (isNotNil(row.marketplace)) {
        return (
          <MarketplaceWithIcon
            title={row.marketplace}
            isIcon={row.autoOrdering}
          />
        )
      }
      return <span title={row.marketplaceCode}>{row.marketplaceCode}</span>
    }

    case CellType.CardStatus: {
      if (isNil(row.cardStatus)) {
        return undefined
      }
      return <StatusCell status={row.cardStatus} />
    }

    case CellType.SyncStatus: {
      if (isNil(row.syncStatus)) {
        return undefined
      }
      return <StatusCell status={row.syncStatus} />
    }

    case CellType.Date: {
      return <DateCell date={row[cellColumnName]} dateFormat={dataFormat}/>
    }
    case CellType.Status: {
      return <OrderStatusCell statusId={row[cellColumnName]} />
    }
    case CellType.UserState: {
      return <UserState value={cellValue}/>
    }
    case CellType.RoundedNumber: {
      return <RoundedNumber cellValue={cellValue}/>
    }
    case CellType.PositionsDiff: {
      return <PositionsDiff row={row}/>
    }
    case CellType.PositionsProductLink: {
      return <PositionsProductLink row={row}/>
    }
    case CellType.PositionsVendorCode: {
      return <PositionsVendorCode row={row}/>
    }
    case CellType.PositionsBarcode: {
      return <PositionsBarcode row={row}/>
    }
    case CellType.PackagingRequirement: {
      return <PackagingRequirement row={row} allRows={allRows}/>
    }
    
    case CellType.BooleanCell: {
      return <BooleanCell
        flag={cellValue}
        row={row}
        onCellChange={onCellChange}
        editingEnabled={editingEnabled}
        cellColumnName={cellColumnName}
      />
    }
    
    case CellType.SettingsMenuCell: {
      return <SettingsMenuCell settingsMenuOptions={settingsMenuOptions} row={row}/>
    }

    case CellType.AttributeTypeCell: {
      return <AttributeTypeCell cellValue={cellValue}/>
    }
    
    case CellType.UserDateIn: {
      return <UserDateInCell values={cellValue} />
    }

    case CellType.RowSelect: {
      return <RowSelectCell row={row} rowSelection={rowSelection}/>
    }
    
    case CellType.UserDateOut: {
      return <UserDateOutCell values={cellValue} />
    }

    case CellType.UserDateEdit: {
      return <UserDateEditCell row={row} onModalOpen={onModalOpen}/>
    }

    case CellType.StatusInHistory: {
      return <HistoryStatus statusId={row[cellColumnName]} />
    }

    case CellType.Prices:
      return <PricesCell prices={row.competitorsPrices} />

    case CellType.WordWrapCell:
      return <WordWrapCell value={cellValue} />

    case CellType.Price:
      return <PriceCell value={cellValue} />

    case CellType.Sum:
      return <SumCell value={cellValue} />

    case CellType.OrderDeltaItems:
      return <OrderDeltaItems row={row}/>

    case CellType.IsEmptyNull:
      return <IsEmptyNull value={cellValue} />

    case CellType.ProductAbcClass:
      return <ProductAbcClass value={cellValue} />

    case CellType.ProductXyzClass:
      return <ProductXyzClass value={cellValue} />

    case CellType.EditQuantity:
      return <EditQuantity
        value={Number(cellValue)}
        onCellChange={onCellChange}
        row={row}
        isLoading={isLoading}
      />
    
    case CellType.PositionsEditQuantity: 
      return <PositionsEditQuantity
        value={Number(row.quantity)}
        onCellChange={onCellChange}
        isTableEditable={isTableEditable}
        row={row}
        isLoading={isLoading}
      />

    case CellType.EditField:
      return <EditField
        value={cellValue}
        isLoading={isLoading}
      />

    case CellType.QuantityInSupply:
      return <QuantityInSupply
        value={cellValue}
        quantityData={row.expectedQuantity}
        isLoading={isLoading}
        isShowGreen={isShowGreen}
      />

    case CellType.QuantityInCopacking:
      return <QuantityInSupply
        value={cellValue}
        quantityData={row.quantity}
        isLoading={isLoading}
        isShowGreen={isShowGreen}
      />

    case CellType.PercentsDiff:
      return (
        <PercentsDiffCell
          directionUp={row.directionUp}
          percentsDiff={row.percentsDiff}
        />
      )

    case CellType.NameValueArraySum:
      return (row[cellColumnName]) ? <NameValueArraySumCell daysWithStocks={row.daysWithStocks} dataArray={row[cellColumnName]}/> : null

    case CellType.DaysWithStocksCell:
      return (row[cellColumnName]) ? <DaysWithStocksCell data={row[cellColumnName]}/> : null

    case CellType.ActionRemove:
      return (
        <span title="Удалить">
          <DeleteIcon
            className="delete_action"
            onClick={(e) => {
              e.stopPropagation()
              onRowDelete?.(row.id)
            }}
          />
        </span>
      )

    case CellType.ActionOpenModal:
      return (
        <span title="Редактировать">
          <OpenModalIcon
            className="openModal_action"
            onClick={(e) => {
              e.stopPropagation()
              onModalOpen?.(row.goodsId)
            }}
          />
        </span>
      )

    case CellType.EditInModal:
      return (
        <span title="Редактировать">
          <EditInModalIcon
            className="openModal_action"
            onClick={(e) => {
              e.stopPropagation()
              onModalOpen?.(row)
            }}
          />
        </span>
      )

    case CellType.PrivilegesName:
      return (
        <PrivilegesNameCell row={row}/>
      )

    case CellType.PrivilegeEdit:
      return (
        <PrivilegeEditCell row={row} onModalOpen={onModalOpen}/>
      )

    case CellType.TemplateEdit:
      return (
        <EditTemplateCell row={row} onModalOpen={onModalOpen}/>
      )

    case CellType.PrivilegeRemove:
      return (
        <PrivilegeRemoveCell row={row} onRowDelete={onRowDelete}/>
      )

    case CellType.PrivilegeCode:
      return (
        <span
          title={cellValue}
          style={{
            display: 'block',
            overflow: 'hidden',
            width: '200px',
            textOverflow: 'ellipsis'
          }}>
          {row.globalPrivilege ? '' : cellValue}
        </span>
      )

    case CellType.PrivilegeUsersQuantity:
      return (
        <PrivilegeUsersQuantityCell row={row}/>
      )


    default:
      return <DefaultCell value={(cellValuePath && row[cellValuePath]) ? row[cellValuePath] : cellValue} />
  }
}

interface ColumnCustom extends Column {
  isShowGreen?: boolean;
  cellType?: CellType,
  cellValuePath?: string,
  editingEnabled?: boolean,
  dataFormat?: DateFormats,
  isSingleString?: boolean,
  quantityData?: string,
  inn?: string,
  shortName?: string,
}

type TableCellComponentProps = Omit<Table.DataCellProps, 'row' | 'column'> & {
  row: any
  column: ColumnCustom
  onRowDelete?: (id: UniqueId) => void
  onModalOpen?: (id: UniqueId) => void
  rowSelection?: {
    selectedCondition: (e) => boolean
    onRowSelect: (row) => void
  }
  onCellChange?: (changes: ChangeSet) => void
  allRows?: boolean
  isLoading?: boolean
  isShowGreen?: boolean
  quantityData?: string
  isTableEditable?: boolean
  settingsMenuOptions?: (e) => Array<IMenuItem>
}

export const TableCellComponent = (props: TableCellComponentProps) => {
  const {
    column,
    row,
    value,
    onRowDelete,
    rowSelection,
    onModalOpen,
    isLoading,
    onCellChange,
    isTableEditable,
    allRows,
    settingsMenuOptions,
    ...rest
  } = props
  const isArchived = row.inArchive
  
  return (
    <StyledTableCell
      {...rest}
      $isArchived={isArchived}
    >
      {getCell(
        row,
        value,
        column.name,
        column.cellType,
        column.cellValuePath,
        allRows,
        onRowDelete,
        onModalOpen,
        onCellChange,
        rowSelection,
        column.dataFormat,
        isLoading,
        column.isShowGreen,
        column.isSingleString,
        isTableEditable,
        column.editingEnabled,
        settingsMenuOptions
      )}
    </StyledTableCell>
  )
}
