import React, { memo, useEffect } from 'react'
import adminPanel from 'assets/images/icons/sidebarIcons/ChildMenuIcons/adminPanel.svg'
import bulkOperations from 'assets/images/icons/sidebarIcons/ChildMenuIcons/bulkOperations.svg'
import docList from 'assets/images/icons/sidebarIcons/ChildMenuIcons/docList.svg'
import goodsList from 'assets/images/icons/sidebarIcons/ChildMenuIcons/goodsList.svg'
import home from 'assets/images/icons/sidebarIcons/ChildMenuIcons/home.svg'
import priceWizard from 'assets/images/icons/sidebarIcons/ChildMenuIcons/priceWizard.svg'
import productList from 'assets/images/icons/sidebarIcons/ChildMenuIcons/productList.svg'
import charts from 'assets/images/icons/sidebarIcons/ChildMenuIcons/charts.svg'
import reports from 'assets/images/icons/sidebarIcons/ChildMenuIcons/reports.svg'
import settingsProfile from 'assets/images/icons/sidebarIcons/ChildMenuIcons/settingsProfile.svg'
import priceHistory from 'assets/images/icons/sidebarIcons/ChildMenuIcons/priceHistory.svg'
import stocksGoodsSupplying from 'assets/images/icons/sidebarIcons/ChildMenuIcons/stocksGoodsSupplying.svg'
import supplyOrders from 'assets/images/icons/sidebarIcons/ChildMenuIcons/supplyOrders.svg'
import reviews from 'assets/images/icons/sidebarIcons/ChildMenuIcons/reviews.svg'
import activity from 'assets/images/icons/sidebarIcons/ChildMenuIcons/activity.svg'
import copackingOrders from 'assets/images/icons/sidebarIcons/ChildMenuIcons/copackingOrders.svg'
import Settings from 'assets/images/icons/sidebarIcons/MenuIcons/Settings.svg'
import ShoppingCart from 'assets/images/icons/sidebarIcons/MenuIcons/ShoppingCart.svg'
import Box from 'assets/images/icons/sidebarIcons/MenuIcons/Box.svg'
import Analytics from 'assets/images/icons/sidebarIcons/MenuIcons/Analytics.svg'
import {
  Collapse,
  Drawer,
  List, ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import { Link, useLocation } from 'react-router-dom'
import logo from 'assets/images/sidebarLogo.svg'
import miniLogo from 'assets/images/sidebarMiniLogo.svg'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'

import { routesList } from './routes'
import {
  CollapseButton,
  CollapseWrapper,
  ListItemStyled,
  SidebarWrapper
} from './styled'

import { localStore } from '../../../services/localStore'
import { useSidebarStore, WEB_LOW_WIDTH } from '../../../../store/sidebar/useSidebarStore'

const MenuIcons = {
  Settings: <img src={ Settings } alt=""/>,
  Box: <img src={ Box } alt=""/>,
  Analytics: <img src={ Analytics } alt=""/>,
  ShoppingCart: <img src={ ShoppingCart } alt=""/>,
}
const ChildMenuIcons = {
  home: <img src={ home } alt=""/>,
  activity: <img src={ activity } alt=""/>,
  goodsList: <img src={ goodsList } alt=""/>,
  productList: <img src={ productList } alt=""/>,
  bulkOperations: <img src={ bulkOperations } alt=""/>,
  supplyOrders: <img src={ supplyOrders } alt=""/>,
  docList: <img src={ docList } alt=""/>,
  stocksGoodsSupplying: <img src={ stocksGoodsSupplying } alt=""/>,
  copackingOrders: <img src={copackingOrders} alt=""/>,
  priceWizard: <img src={ priceWizard } alt=""/>,
  priceHistory: <img src={ priceHistory } alt=""/>,
  reviews: <img src={ reviews } alt=""/>,
  charts: <img src={ charts } alt=""/>,
  reports: <img src={ reports } alt=""/>,
  adminPanel: <img src={ adminPanel } alt=""/>,
  settingsProfile: <img src={ settingsProfile } alt=""/>,
}

export const Sidebar = memo(() => {
  const sidebarOpen = useSidebarStore((state) => state.sidebarOpen)
  const setSidebarOpen = useSidebarStore((state) => state.setSidebarOpen)
  const expand = useSidebarStore((state) => state.expand)
  const setExpand = useSidebarStore((state) => state.setExpand)
  const setForcedSidebarState = useSidebarStore((state) => state.setForcedSidebarState)
  const forcedSidebarState = useSidebarStore((state) => state.forcedSidebarState)
  const { pathname } = useLocation()
  const routes = routesList()

  const handleDrawerOpen = () => {
    if (window.innerWidth < WEB_LOW_WIDTH) {
      setForcedSidebarState(true)
    }
    setSidebarOpen(true)
  }

  const handleDrawerClose = () => {
    if (window.innerWidth < WEB_LOW_WIDTH) {
      setForcedSidebarState(false)
    }
    setSidebarOpen(false)
  }

  const updateSidebarState = () => {
    if (!forcedSidebarState) {
      setSidebarOpen(window.innerWidth > WEB_LOW_WIDTH)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', updateSidebarState)
    return () => {
      window.removeEventListener('resize', updateSidebarState)
    }
  }, [forcedSidebarState])

  const isSelected = (href) => {
    if (pathname.includes('bulkoperations')) {
      return href.includes('bulkoperations')
    }
    if (href === '/') {
      return href === pathname
    }
    return !!pathname.match(`^${ href }`)
  }

  const handleChangeExpand = (index) => {
    const rest = JSON.parse(JSON.stringify(expand))
    rest[index].open = !expand[index].open
    localStore.set('expandedSidebarList', JSON.stringify(rest))
    setExpand(rest)
  }

  const includesPath = (href) => {
    let include = false
    href.forEach(el => {
      if (isSelected(el)) {
        include = true
      }
    })
    return include
  }

  return (
    <SidebarWrapper>
      <div id="sidebar-container" className={sidebarOpen ? 'sidebar-container' : 'sidebar-container closed'}>
        <Drawer variant="permanent">
          <div className="logo-wrapper">
            <img className="full-img" src={ logo }
              alt=""/>
            <img className="mini-img" src={ miniLogo } alt=""/>
          </div>

          <List className="material-list">
            { routes.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
              <div key={ index }>
                {item.icon ?
                  <ListItemStyled
                    onClick={() => {handleChangeExpand(index)}}
                    $includes={includesPath(item.child.map(el => el.href))}>
                    <ListItemIcon>
                      { MenuIcons[item.icon] }
                    </ListItemIcon>

                    <ListItemText primary={ item.title }/>
                    {expand[index].open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </ListItemStyled>
                  :
                  <div style={{ height: '32px' }}/>
                }
                { item.child.length && (
                  <Collapse in={ item.title ? expand[index].open : true } timeout="auto">
                    { item.child.map((child, childIndex) => (
                    // eslint-disable-next-line react/no-array-index-key
                      <List component="div" disablePadding={ true } key={ childIndex }>
                        <ListItemButton
                          className={ `${ isSelected(child.href) ? 'menu-link-active' : '' }` }
                          title={ sidebarOpen ? '' : child.title }
                          component={ Link }
                          to={ child.href }
                        >
                          <ListItemIcon>{ ChildMenuIcons[child.icon] }</ListItemIcon>
                          <ListItemText primary={ child.title }/>
                        </ListItemButton>
                      </List>
                    )) }
                  </Collapse>
                ) }
              </div>
            )) }
            <CollapseWrapper $isActive={ sidebarOpen }>
              { sidebarOpen ? (
                <CollapseButton onClick={ handleDrawerClose }>
                  <KeyboardDoubleArrowLeftIcon/>
                </CollapseButton>
              ) : (
                <CollapseButton onClick={ handleDrawerOpen }>
                  <KeyboardDoubleArrowRightIcon/>
                </CollapseButton>
              ) }
            </CollapseWrapper>
          </List>
        </Drawer>
      </div>
    </SidebarWrapper>
  )
}
)
