import { useEffect, useRef } from 'react'
import { useNotificationsStore } from 'store/notifications/useNotificationsStore'

import { isDevEnv } from '../../config'


export const useWebSocket = () => {
  const ws = useRef<any>(null)
  const setWebSocket = useNotificationsStore((state) => state.setWebSocket)
  const setMessage = useNotificationsStore((state) => state.setMessage)
  const sendMessage = useNotificationsStore((state) => state.sendMessage)
  const setShowMessage = useNotificationsStore((state) => state.setShowMessage)

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') setShowMessage(false)
      if (document.visibilityState === 'visible' && ws.current?.readyState !== WebSocket.OPEN) {
        connect()
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  const connect = () => {
    if (document.visibilityState === 'visible') {
      const pingFunction = setInterval(() => sendMessage({ type: 'ping' }), 30000)
      if (ws.current) {
        ws.current.close() // Закрыть предыдущее соединение перед подключением
      }

      ws.current = new WebSocket(`wss://${window.location.hostname}/api/notifications/ws`)
      // сделано для проги для теста puppetry, поскольку он явно не видит wss протокол, локально сокет работать не будет

      ws.current.onopen = () => {
        setWebSocket(ws.current)
      }

      ws.current.onclose = () => {
        clearInterval(pingFunction)
        setTimeout(() => {
          connect()
        }, isDevEnv ? 60000 : 5000)
      }

      ws.current.onerror = () => {
        ws.current.close()
      }

      ws.current.onmessage = (event) => {
        const notification = JSON.parse(event.data)
        if (notification.type !== 'pong' && typeof notification !== 'string') {
          setMessage(notification)
        }
      }
    }
  }

  useEffect(() => {
    connect() // первое подключение при монтировании компонента

    return () => {
      if (ws.current) {
        ws.current.close(1000, 'Closing normally') // закрыть соединение при размонтировании компонента
      }
    }
  }, [setWebSocket, setMessage])

  return ws.current
}