import styled from 'styled-components'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { Box } from '@mui/material'

export const StyledTableBox = styled(Box)`
  height: 112px;
  padding: 16px 16px 16px 24px;
  background: #FFFFFF;
  box-shadow: 0px -1px 0px 0px #E0E0E0 inset;
`

export const StyledHeader = styled.div<{ open: boolean }>`
  min-height: 56px;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  top: 50px;
  left: 0;
  z-index: 1;
  background-color: #FFFFFF;
  
  #header-container {
    width: 100%;
    height: 100%;
    padding-left: ${({ open }) => open ? '278px' : '86px'};
    transition: all 0.3s ease-out 0.1s;
    padding-right: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    
    #greetings-text {
      font-size: 20px;
      color: #00000099;
    }
    
    #settings-button-container {
      margin-left: auto;
      margin-right: 0;
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 24px;
      
      #version-text {
        font-size: 12px;
        color: #00000061;
      }
    }
  }
`
export const StyledWidthProviderResponsive = styled(WidthProvider(Responsive))<{ width: number, height: number, editable: boolean }>`
  width: 100%;
  min-height: 100%;
  margin: -16px;
  background-color: ${({ editable }) => editable ? 'rgba(250,250,250,0.60)' : ''};
  background-image: ${({ editable }) => editable ?
    'repeating-linear-gradient(#efefef 0 16px, transparent 16px 100%),' +
          '  repeating-linear-gradient(90deg, #efefef 0 16px, transparent 16px 100%);'
    : ''};
  background-size: ${({ height, width, editable }) => editable ? `${width}px ${height}px` : ''};

  #content {
    width: 100%;
  }

  .react-grid-layout {
    background: none !important;
  }

  .columns {
    -moz-columns: 120px;
    -webkit-columns: 120px;
    columns: 120px;
  }

  .react-grid-item {
    animation: ${({ editable }) => editable ? 'tilt-shaking 2s infinite' : ''};
    border-radius: 8px;
  }

  .react-grid-item:not(.react-grid-placeholder) {
    background: #fff;
  }

  .react-grid-item.resizing {
    opacity: 0;
    transition: 0.3s;
  }

  .react-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 24px;
  }

  .react-grid-item .minMax {
    font-size: 12px;
  }

  .react-grid-item .add {
    cursor: pointer;
  }

  .react-grid-dragHandleExample {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
  }

  li b {
    font-size: 19px;
    line-height: 14px;
  }

  .react-grid-item.react-grid-placeholder {
    background: #dedede;
    opacity: 0.8;
    transition: 0.3s;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  @keyframes tilt-shaking {
    0% {
      transform: rotate(0.2deg);
    }
    4% {
      transform: rotate(-0.2deg);
    }
    8% {
      transform: rotate(0.2deg);
    }
    12% {
      transform: rotate(-0.2deg);
    }
    16% {
      transform: rotate(0.2deg);
    }
    20% {
      transform: rotate(-0.2deg);
    }
    21% {
      transform: rotate(0deg);
    }
  }
  
`

