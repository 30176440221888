import styled from 'styled-components'
import { Box } from '@mui/material'
import { TabPanel } from 'shared/ui/components'

export const StyledTableBox = styled(Box)`
  position: relative;
  min-height: 216px;
  overflow: hidden;
`

export const StyledTabPanel = styled(TabPanel)`
  padding: 0 24px;
  .MuiPaper-root {
    overflow: visible;
  }
`

export const ModalContainer = styled.div`
  width: 760px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .MuiDialogTitle-root {
      font-size: 20px;
  }
  
  .products-container {
    margin-top: 8px;
    max-height: 262px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .result-item {
    margin-bottom: 0;
  }
  
  form {
    margin-bottom: 0 !important;
    .algorithm-container {
      width: 100%
    }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type="number"] {
    -moz-appearance: textfield;
  }
`