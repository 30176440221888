import React, { ReactNode, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import usePending from '../../lib/hooks/PendingHook'
import { userInfo, viewerModel } from '../../../store/user'
import { OpenedStreamsProvider } from '../components/StreamEvents/context/OpenedStreamsContext'
import { useOpenedEventStream } from '../../lib/hooks/eventStream/useOpenedEventStream'
import { Header } from '../components/header'
import { Sidebar } from '../components'
import { AsyncServices } from '../../lib/asyncServices'
import { useSidebarStore } from '../../../store/sidebar/useSidebarStore'

// hide input type="number" arrows
const Layout = styled.div<{ $isActive, $isAuthorized }>`
  min-height: 100%;
  height: 100%;

  > div {
    transition: all 0.3s ease-out 0.1s;
  }

  .main-content {
    padding-left: ${ (props) => {
    if (props.$isAuthorized) {
      return (props.$isActive ? '250px' : '56px')
    }
    return '0px'
  } };

    > div {
      padding: ${ (props) => (props.$isAuthorized ? '50px 28px 28px' : '0px') };
    }
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`
interface AppLayoutProps {
  children: ReactNode
}

const RestoreUser = () => {
  const [restoreUserInfo] = usePending(userInfo)
  useEffect(() => {
    restoreUserInfo()
  }, [])
  return null
}

export const AppLayout = ({ children }: AppLayoutProps) => {
  const sidebarOpen = useSidebarStore((state) => state.sidebarOpen)
  const { openedStreams, changeStreamState } = useOpenedEventStream()
  const status = useSelector(viewerModel.viewerStatusSelector)
  const specialCondition = !window.location.pathname.includes('showcase')

  return (
    <OpenedStreamsProvider value={{ openedStreams, changeStreamState }}>
      <Layout $isActive={sidebarOpen} $isAuthorized={status === 'authorized'}>
        { status === 'authorized' && specialCondition &&
          <>
            <AsyncServices/>
            <Header />
            <Sidebar />
            <RestoreUser/>
          </>
        }
        {children}
      </Layout>
    </OpenedStreamsProvider>
  )
}
