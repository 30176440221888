import { memo } from 'react'
import { wbproxyApi } from 'shared/api'
import { Option } from 'shared/ui/components/interface'
import { FieldRenderProps } from 'react-final-form'
import { sort } from 'shared/lib/utils/sort'
import { AutocompleteSearchField } from 'shared/ui/components/form/AutocompleteSearchField'

export interface TNVEDProps extends FieldRenderProps<string> {
  companymarketplaceId: number,
  tnvedObject: string,
  subjectId: number
}

const getOptionFromTNVED = ({ code }: wbproxyApi.model.TNVEDCode) => ({
  label: `${code}`,
  value: code
})

export const TNVEDCode = ({ tnvedObject, companymarketplaceId, subjectId, ...props } : TNVEDProps) => {
  const TNVEDQuery = wbproxyApi.useFetchTNVEDQuery<Array<Option>>(
    tnvedObject,
    companymarketplaceId,
    subjectId,
    {
      enabled: !!companymarketplaceId,
      select: ({ data }) => sort(data, 'code', 'desc').map(getOptionFromTNVED), 
      staleTime: Infinity,
    }
  )

  const handleChange = (value) => {
    props.input.onChange(value.value)
  }

  return (
    <AutocompleteSearchField
      {...props}
      options={TNVEDQuery.data ?? []}
      onChange={handleChange}
      noOptionsText="Введите строку для поиска"
    />
  )
}

export const TNVEDCodeMemo = memo(TNVEDCode)
