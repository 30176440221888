import React, { useEffect, useRef, useState } from 'react'
import { useNotificationsStore } from 'store/notifications/useNotificationsStore'

import { NotificationItem } from './NotificationItem'
import { PlugNotifications } from './PlugNotifications'
import { StyledNotificationsContainer } from './styled'

export const NotificationsContainer = () => {
  const messages = useNotificationsStore((store) => store.messages)
  const showMessage = useNotificationsStore((store) => store.showMessage)
  const setShowMessage = useNotificationsStore((store) => store.setShowMessage)
  const sendMessage = useNotificationsStore((store) => store.sendMessage)
  const setMessage = useNotificationsStore((store) => store.setMessage)

  const [isAnimating, setIsAnimating] = useState(false)
  const [isMounted, setIsMounted] = useState(false)
  const timeoutRef = useRef<number | null>(null)

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'notificationsMessages') {
        const localStoreMessages = JSON.parse(localStorage.getItem('notificationsMessages') || '')
        setMessage(localStoreMessages || [])
      }
    }

    // Подписка на событие изменения локального хранилища
    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  useEffect(() => {
    if (showMessage) {
      setIsMounted(true)
      requestAnimationFrame(() => setIsAnimating(true))
    } else {
      setIsAnimating(false)
      timeoutRef.current = window.setTimeout(() => {
        setIsMounted(false)
        timeoutRef.current = null
      }, 500) // Длительность должна совпадать с анимацией
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [showMessage])

  useEffect(() => {
    const keyDownHandler = event => {
      const notificationIconElement = document.getElementById('notification-icon')
      if (showMessage &&
        !document.getElementById('notification-snack')?.contains(event.target) &&
        !notificationIconElement?.contains(event.target)) {
        setShowMessage(false)
      }
    }

    document.addEventListener('mousedown', keyDownHandler)

    return () => {
      document.removeEventListener('mousedown', keyDownHandler)
    }
  })

  if (!isMounted) {
    return null // Не рендерим компонент, когда он не должен быть видимым
  }

  const deleteAllMessages = () => {
    sendMessage({
      type: 'delete_all'
    })
    setMessage([])
    localStorage.setItem(
      'notificationsMessages', JSON.stringify([]))
  }

  return (
    <StyledNotificationsContainer>
      <div id="notification-snack" className={`container ${isAnimating ? 'show' : 'hide'}`}>
        <div className="title">
          <span>Уведомления</span>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */ }
          { messages?.length !== 0 ? <span className="text-button" onClick={deleteAllMessages}>Очистить</span> : <></> }
        </div>
        { messages?.length !== 0 ?
          <div className="notifications-container">
            { messages?.map((messageItem) => (
              <NotificationItem key={ messageItem.id } item={ messageItem } />
            )) }
          </div>
          :
          <PlugNotifications/>
        }
      </div>
    </StyledNotificationsContainer>
  )
}